import {
    AgendaTopic,
    SUBJECT_EXCEL_REPORTING,
    SUBJECT_MANAGEMENT_REPORTING,
    SUBJECT_POWER_BI,
    SUBJECT_REPORT_HEALTH_CHECK,
} from "../CommonService";

export interface TrainingOffer {
    name: string ;
    duration: string;
    description: string[];
    targetGroup: string;
    language: string;
    schedule: string;
    organisationalInfo: string;
    groupSize: string;
    requiredEquipment: string;
    agenda?: AgendaTopic[];
    iconSvgPath: string;
}

export const TRAINING_OFFERS = [
    SUBJECT_MANAGEMENT_REPORTING,
    SUBJECT_POWER_BI,
    SUBJECT_REPORT_HEALTH_CHECK,
    SUBJECT_EXCEL_REPORTING,
];

export const getTrainingOfferNamesWithDays = () => {
    return TRAINING_OFFERS.map(getTrainingOfferNameWithDays);
};

export const getTrainingOfferNameWithDays = (trainingOffer: TrainingOffer) => {
    const {name, duration} = trainingOffer;
    return `${name} (${duration})`;
};

export const getTrainingOfferByName = (courseId: string): TrainingOffer => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <TrainingOffer>TRAINING_OFFERS.find(trainingOffer => trainingOffer.name.toUpperCase() === courseId.toUpperCase());
};
