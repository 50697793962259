import React, {FunctionComponent} from 'react';
import {Link} from "react-router-dom";
import {NavigationItemView} from "./NavigationItemView";
import {NAVIGATION_LINKS} from "./NavigationService";
import {LogoMarkView} from "./LogoMarkView";

interface Props {
    dark: boolean;
    onNavigationToggle: () => void;
}

export const NavigationLargeView: FunctionComponent<Props> = ({ dark, onNavigationToggle }) => {
    return (
        <div className="relative pt-3 px-0 sm:px-6 lg:px-8">
            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                        <Link to="/" aria-label="Home">
                            <LogoMarkView />
                        </Link>
                        <div className="-mr-2 flex items-center md:hidden">
                            <button type="button"
                                    onClick={onNavigationToggle}
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                                    id="main-menu" aria-label="Main menu" aria-haspopup="true">
                                <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M4 6h16M4 12h16M4 18h16"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4">
                    {NAVIGATION_LINKS.map(link => <NavigationItemView name={link.name} path={link.path} dark={dark} exact={link.exact} />)}
                </div>
            </nav>
        </div>
    )
};
