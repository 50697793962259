import React, {FunctionComponent} from 'react';

interface Props {
    clientName: string;
}

export const ClientCardView: FunctionComponent<Props> = ({ clientName }) => {
    return (
        <div className="bg-gray-100 py-10 sm:py-6 md:py-6 lg:py-8 xl:py-10 px-6 md:px-2 lg:px-6 xl:px-6 text-center text-gray-600 font-semibold text-xl sm:text-lg md:text-lg lg:text-xl xl:text-2xl">
            <p className="">
            {clientName}
            </p>
        </div>
    );
};
