import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {ContactView} from "./contact/ContactView";
import {HomeHeroView} from "./home/HomeHeroView";
import {ImpressumView} from "./impressum/ImpressumView";
import {TrainingOverViewContainer} from "./training/TrainingOverviewContainer";
import {PrivacyPolicyView} from "./privacy-policy/PrivacyPolicyView";
import {CookiesPolicyView} from "./cookies-policy/CookiesPolicyView";
import {TrainingOfferItemDetailedView} from "./training/TrainingOfferItemDetailedView";
import {AboutContainer} from "./about/AboutContainer";
import {FooterContainer} from "./footer/FooterContainer";
import {ScrollToTop} from "./ui/scrollToTop";

function App() {
  return (
      <div className="overflow-hidden">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomeHeroView}/>
          <Route path="/kontakt" component={ContactView}/>
          <Route path="/impressum" component={ImpressumView}/>
          <Route path="/training/:courseId" component={TrainingOfferItemDetailedView}/>
          <Route path="/training" component={TrainingOverViewContainer}/>
          <Route path="/datenschutz" component={PrivacyPolicyView}/>
          <Route path="/cookies" component={CookiesPolicyView}/>
          <Route path="/referenzen" component={AboutContainer}/>
        </Switch>
        <FooterContainer />
      </Router>
      </div>
  );
}

export default App;
