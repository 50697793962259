import React, {FunctionComponent} from 'react';

interface Props {
    title: string;
    text: string;
}

export const KeyInfoView: FunctionComponent<Props> = ({ title, text}) => {
    return (
        <div className="flex">
            <div className="flex-shrink-0 pt-2">
                <span className="h-4 w-4 bg-indigo-600 flex rounded-full text-lg leading-10 font-display"/>
            </div>
            <div className="ml-4">
                <p className="mt-1 text-lg leading-6 font-medium text-gray-900">
                    {title}
                </p>
                <p className="mt-1 max-w-xl text-base text-gray-500 lg:mt-2">
                    {text}
                </p>
            </div>
            </div>
    )
};
