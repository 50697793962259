import React, {FunctionComponent} from 'react';
import {NavigationContainer} from "../navigation/NavigationContainer";
import {PolicySectionView} from "./PolicySectionView";
import {FurtherInfoView} from "./FurtherInfoView";
import {
    AUFTRAGER_TEXT,
    AUFTRAGER_TITLE,
    COMPANY_ADDRESS,
    COMPANY_ADDRESS_LABEL,
    COMPANY_EMAIL,
    COMPANY_NAME,
    COMPANY_NAME_LABEL,
    COMPANY_PHONE_NUMBER,
    DATA_TIME_TEXT,
    DATA_TIME_TITLE,
    DATA_WEITER_TEXT,
    DATA_WEITER_TITLE,
    GRUNDLAGE_TEXT,
    GRUNDLAGE_TITLE,
    PHONE_LABEL,
    PRIVACY_HEADER,
    PRIVACY_SUB_HEADER,
    RESPONSIBLE_PARTY_LABEL
} from "../CommonService";


export const PrivacyPolicyView: FunctionComponent = () => {
    const COMPANY_INFO = [`${COMPANY_NAME_LABEL}: ${COMPANY_NAME}`, `${COMPANY_ADDRESS_LABEL}: ${COMPANY_ADDRESS.line_1}, ${COMPANY_ADDRESS.line_2}, ${COMPANY_ADDRESS.line_3}`, `${PHONE_LABEL}: ${COMPANY_PHONE_NUMBER}`];
    const PROCESSING_INFO = [COMPANY_NAME, COMPANY_PHONE_NUMBER, COMPANY_EMAIL, 'Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in irgendeiner Weise verletzt worden sind, können Sie sich bei der Datenschutzbehörde beschweren.'];
    return (
        <div className="pb-20">
            <div className="bg-gray-200">
                <NavigationContainer useWrapper dark />
                <div className="text-center max-w-screen-xl mx-auto mt-10 md:mt-16 pb-16">
                    <h1 className="text-2xl sm:text-2xl md:text-4xl pb-8 tracking-tight xl:tracking-wide leading-10 font-extrabold text-black sm:leading-none">{PRIVACY_HEADER}</h1>
                    <div className="text-lg md:text-xl text-gray-700 max-w-3xl mx-auto">
                        {PRIVACY_SUB_HEADER}
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="mx-6 md:mx-32 pt-6 pb-12">
                    <PolicySectionView title={RESPONSIBLE_PARTY_LABEL} text={COMPANY_INFO} addPadding={false} />
                    <PolicySectionView title={AUFTRAGER_TITLE} text={AUFTRAGER_TEXT} addPadding={false} />
                    <PolicySectionView title={GRUNDLAGE_TITLE} text={GRUNDLAGE_TEXT} addPadding={false} />
                    <PolicySectionView title={DATA_TIME_TITLE} text={DATA_TIME_TEXT} addPadding={false} />
                    <PolicySectionView title={DATA_WEITER_TITLE} text={DATA_WEITER_TEXT} addPadding />
                    <PolicySectionView text={PROCESSING_INFO} addPadding={false} />
                    <FurtherInfoView />
                </div>
            </div>
        </div>
    )
};
