import React, {FunctionComponent} from "react";
import {InputView} from "./InputView";
import {
    EMAIL_LABEL,
    FIRST_NAME_LABEL,
    LAST_NAME_LABEL, MESSAGE_LABEL, SEND_LABEL, SUBJECT_GENERAL_INQUIRY, SUBJECT_LABEL, TELEPHONE_LABEL,
    TITLE_LABEL,
    TITLE_OPTION_FEMALE,
    TITLE_OPTION_MALE
} from "../CommonService";
import {OptionView} from "./OptionView";
import {TextAreaView} from "./TextAreaView";
import {getTrainingOfferNamesWithDays} from '../training/TrainingService';
import {useContactForm} from "./useContactForm";
import {ContactFormData} from "./useSendEmail";

interface Props {
    sendEmail: (formData: ContactFormData) => void;
}

export const ContactFormView: FunctionComponent<Props> = ({ sendEmail }) => {
    const {
        firstName,
        lastName,
        title,
        email,
        phoneNumber,
        subject,
        message,
        updateFirstName,
        updateLastName,
        updateTitle,
        updateEmail,
        updatePhoneNumber,
        updateSubject,
        updateMessage,
        handleFormSubmit,
    } = useContactForm(sendEmail);

    return (
        <form onSubmit={handleFormSubmit} method="POST">
            <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                        <InputView id='first_name' columns={3} label={FIRST_NAME_LABEL} value={firstName} onChange={updateFirstName} />
                        <InputView id='last_name' columns={3} label={LAST_NAME_LABEL} value={lastName} onChange={updateLastName} />
                        <OptionView label={TITLE_LABEL} options={[TITLE_OPTION_FEMALE, TITLE_OPTION_MALE]} id='title' cols={3} value={title} onSelect={updateTitle} />
                        <InputView id='email_address' columns={6} label={EMAIL_LABEL} value={email} onChange={updateEmail} />
                        <InputView id='phone_number' columns={6} label={TELEPHONE_LABEL} value={phoneNumber} onChange={updatePhoneNumber} />
                        <OptionView
                            onSelect={updateSubject}
                            label={SUBJECT_LABEL}
                            options={[SUBJECT_GENERAL_INQUIRY, ...getTrainingOfferNamesWithDays()]}
                            id='subject'
                            cols={6}
                            value={subject}
                        />
                        <TextAreaView label={MESSAGE_LABEL} id="message" onChange={updateMessage} value={message} />
                    </div>
                </div>
                <div className="grid grid-cols-6 gap-6 py-3">
                    <div className="col-span-6 px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                            className="w-full py-4 px-4 border border-transparent text-lg leading-5 font-semibold rounded-md text-white bg-indigo-700 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue active:bg-indigo-600 transition duration-150 ease-in-out"
                        >
                            {SEND_LABEL}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
};
