import React, {FunctionComponent} from "react";
import {TrainingOverviewHeader} from "./TrainingOverviewHeader";
import {TrainingOverviewMain} from "./TrainingOverviewMain";


export const TrainingOverViewContainer: FunctionComponent = () => {
    return (
        <>
            <TrainingOverviewHeader />
            <TrainingOverviewMain />
        </>
    )
};
