import React, {FunctionComponent} from 'react';

export const LogoMarkView: FunctionComponent = () => {
    const textStyle = {
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontStretch: 'normal',
        fontSize: 19.9878,
        lineHeight: 1.25,
        fontFamily:'Selawik light',
        fillOpacity: 1,
        strokeWidth: 0.267695,
    };

    const elipseStyle = {
        fill: 'none',
        fillOpacity: 1,
        strokeWidth: 11.64,
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
        strokeOpacity: 1,
    };

    const rectStyle = {
        fillOpacity: 1,
        strokeWidth: 0,
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
        strokeOpacity: 1,
    };

    const rect2style = {
        fillOpacity: 1,
        stroke: 'none',
        strokeWidth: 0,
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
    };

    const pathStyle = {
        opacity: 0.992,
        fillOpacity: 1,
        stroke: 'none',
        strokeWidth: 30.3154,
        strokeLinejoin: 'round',
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
    };

    return (
<svg
className="fill-current stroke-current text-indigo-600 h-5 sm:h-7 md:h-8 w-auto"
    xmlns="http://www.w3.org/2000/svg"
    width="141.96208mm"
    height="74.183716mm"
    viewBox="0 0 141.96208 74.183716"
    version="1.1"
    id="svg8"
        >
        <g
    id="layer1"
    transform="translate(-15.657279,-10.783807)">
        <text
    style={textStyle}
    x="-218.93837"
    y="-1.3912864"
    id="text12-6-29"
    transform="scale(0.9889172,1.011207)"><tspan
    x="-218.93837"
    y="-1.3912864"
    style={textStyle}
    id="tspan869-5-3" /></text>
        <g
    id="g1224"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1226"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1228"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1230"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1232"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1234"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1236"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1238"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1240"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1242"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1244"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1246"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1248"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1250"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <g
    id="g1252"
    transform="matrix(0.0926549,0,0,0.0926549,-248.21294,-69.826146)" />
        <ellipse
    style={elipseStyle}
    id="path1183-5-2-6-44-1-6"
    cx="120.52753"
    cy="47.875664"
    rx="31.271841"
    ry="31.271856"
        />
        <rect
    style={rectStyle}
    id="rect1501"
    width="29.37776"
    height="11.641632"
    x="44.969864"
    y="59.796227"
    ry="5.820816"
        />
        <rect
    style={rect2style}
    id="rect1145-4-5-8-8-9-4-5-2-4-4-00"
    width="13.2004"
    height="80.00396"
    x="45.760941"
    y="-4.9701977"
    ry="6.6002002"
    transform="matrix(0.95343581,0.30159601,-0.40088541,0.91612821,0,0)"
        />
        <rect
    style={rect2style}
    id="rect1145-4-5-8-8-9-4-5-2-4-4-00-2"
    width="13.2004"
    height="80.00396"
    x="-46.724731"
    y="25.491304"
    ry="8.0004091"
    transform="matrix(-0.95343581,0.301596,0.4008854,0.91612821,0,0)"
        />
        <path
            // @ts-ignore
    style={pathStyle}
    id="path873"
    d="m 120.5548,25.53568 a 22.34,22.34 0 0 1 22.31272,22.353622 22.34,22.34 0 0 1 -22.33999,22.326362"
        />
        </g>
        </svg>

)
};
