import React, {FunctionComponent} from 'react';
import {CLIENTS, CLIENTS_LABEL} from "../CommonService";
import {ClientCardView} from "./ClientCardView";

export const ClientsContainer: FunctionComponent = () => {
    return (
        <div>
            <div className="text-lg text-gray-700 font-medium tracking-wider tracking-wider text-center">
                {CLIENTS_LABEL.toUpperCase()}
            </div>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-1 mt-10 md:w-full xl:max-w-screen-xl sm:mx-10 md:mx-auto">
                {CLIENTS.map(client => <ClientCardView clientName={client}/>)}
            </div>
        </div>
    )
};
