import React, {FunctionComponent} from 'react';
import {KEY_INFO_TITLES,} from "../CommonService";
import {TrainingOffer} from './TrainingService';
import {KeyInfoView} from "./KeyInfoView";

interface Props {
    trainingOffer: TrainingOffer;
    imageLayout: boolean;
}

export const KeyInfoContainer: FunctionComponent<Props> = ({ trainingOffer, imageLayout}) => {
    const { targetGroup, language, schedule, organisationalInfo, groupSize, requiredEquipment } = trainingOffer;
    const divClass = imageLayout ? 'content-around w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-full mx-3 sm:mx-10 md:mx-auto mt-8 space-y-6 xl:space-y-0 xl:grid xl:grid-cols-2 lg:gap-8 lg:mt-16'
    : 'w-full sm:w-full md:w-3/4 lg:w-full xl:w-full md:mx-auto  mt-8 space-y-6 xl:space-y-0 lg:grid lg:grid-cols-1 xl:grid-cols-2 lg:gap-8 lg:mt-16';

    return(
        <div className={divClass}>
            <KeyInfoView title={KEY_INFO_TITLES.TARGET_GROUP} text={targetGroup}/>
            <KeyInfoView title={KEY_INFO_TITLES.LANGUAGE} text={language}/>
            <KeyInfoView title={KEY_INFO_TITLES.DURATION} text={schedule}/>
            <KeyInfoView title={KEY_INFO_TITLES.ORGANISATIONAL_INFO} text={organisationalInfo}/>
            <KeyInfoView title={KEY_INFO_TITLES.GROUP_SIZE} text={groupSize}/>
            <KeyInfoView title={KEY_INFO_TITLES.REQUIRED_EQUIPMENT} text={requiredEquipment}/>
        </div>
    )
};
