import React, { FunctionComponent } from 'react';
import {ContactFormView} from "./ContactFormView";
import {NavigationContainer} from "../navigation/NavigationContainer";
import {ContactInfoView} from "./ContactInfoView";
import {GET_IN_TOUCH_LABEL} from "../CommonService";
import {useSendEmail} from "./useSendEmail";

export const ContactView: FunctionComponent = () => {
    const { result, showResponse, sendEmail } = useSendEmail();
    return (
    <>
        <NavigationContainer useWrapper dark/>
        <div className="bg-gray-100 pb-40">
            <div className="py-20 sm:px-6 lg:px-40">
                <div className="mt-10 sm:mt-0">
                    <div className="flex">
                        <div className="w-1/2">
                            <div className="">
                                <h1 className="text-3xl font-extrabold leading-6 text-gray-900">{GET_IN_TOUCH_LABEL}</h1>
                                <ContactInfoView />
                            </div>
                        </div>
                        <div className="w-1/2">
                            {!showResponse && (
                                <ContactFormView sendEmail={sendEmail} />
                            )}
                            {showResponse && (
                                <h2 className="text-xl">{result?.message}</h2>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hidden sm:block">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};
