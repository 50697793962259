import React, {FunctionComponent} from 'react';
import { useParams } from 'react-router-dom';
import {BACK_TO_ALL_TRAININGS_LABEL } from "../CommonService";
import {KeyInfoContainer} from "./KeyInfoContainer";
import {NavigationContainer} from "../navigation/NavigationContainer";
import {AgendaContainer} from "./AgendaContainer";
import {DescriptionView} from "./DescriptionView";
import {getTrainingOfferByName} from './TrainingService';
import img from '../assets/TrainingDetails.jpg';

export const TrainingOfferItemDetailedView: FunctionComponent = () => {
    const { courseId } = useParams();
    const trainingOffer = getTrainingOfferByName(courseId);
    if(!trainingOffer) {
        return  null;
    }
    const { name, description } = trainingOffer;
    const agenda = trainingOffer.agenda || undefined;
    const containerClass = !!agenda ? 'lg:flex xl:flex mt-20 mx-4 sm:mx-8 md:mx-10 lg:mx-24 xl:mx-32' : 'md:flex-col xl:flex-row md:flex  mt-10 sm:mt-20 mx-0 xl:mx-20';
    const descriptionDiv = !!agenda ? 'lg:w-1/2 xl:w-2/3 xl:pr-40 lg:pr-16 md:pr-0 sm:pr-0' : 'xl:w-2/3 xl:pr-40  md:pr-0 sm:pr-0';
    return (
        <div className="bg-gray-100 pb-40">
            <NavigationContainer useWrapper dark />
            <div className={containerClass}>
                <div className={descriptionDiv}>
                    <DescriptionView name={name} description={description} imageLayout={!agenda} />
                    {!agenda && (
                        <div className="xl:hidden w-full sm:pt-6 md:pt-6 lg:pt-6 md:pb-6 lg:pb-0">
                            <img src={img} alt="team analysing reports" className="w-full object-cover h-64 sm:h-64 md:h-64" />
                        </div>
                    )}
                    <KeyInfoContainer trainingOffer={trainingOffer} imageLayout={!agenda}/>
                </div>
                {!!agenda && (
                    <div className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/3">
                        <AgendaContainer agenda={agenda} />
                    </div>
                )}
                {!agenda && (
                    <div className="hidden xl:block w-full xl:w-1/3">
                    <img src={img} alt="team analysing reports" className="w-full object-cover h-64 xl:h-full" />
                    </div>
                )}
            </div>
                <div className="rounded-md pt-24 mx-auto flex bg-gray-100">
                    <a href="/training"
                       className="px-8 py-3 border mx-auto content-center border-transparent shadow text-base leading-6 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                        {BACK_TO_ALL_TRAININGS_LABEL}
                    </a>
                </div>
        </div>
    )
};
