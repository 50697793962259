import React, {FunctionComponent} from "react";
import {NavLink} from "react-router-dom";

interface Props {
    name: string;
    path: string;
    dark: boolean;
    exact: boolean;
}

export const NavigationItemView: FunctionComponent<Props> = ({ name, path, exact, dark }) => {
    const linkClass = dark
        ? 'mr-8 font-medium text-gray-500 hover:text-gray-200 transition duration-150 ease-in-out'
        : 'mr-8 font-medium text-gray-700 hover:text-gray-500 transition duration-150 ease-in-out';
    return (
        <NavLink
            to={path}
            className={linkClass}
            activeClassName="text-indigo-600 hover:text-indigo-600"
            exact={exact}
            >
            {name}
        </NavLink>
    )
};
