import React, {FunctionComponent} from 'react';
import {AVAILABLE_TRAININGS_LABEL, SERVICES_LABEL, SERVICES_TEXT} from "../CommonService";

export const HomeOverviewView: FunctionComponent = () => {
    return (
        <div className="bg-gray-200 overscroll-contain overflow-hidden pb-20 sm:pb-10 md:pb-12 lg:pb-10 xl:pb-20 px-1 md:px-0 content-center">
            <div className="py-4 sm:py-16 sm:pt-10 md:pt-8 lg:pt-12 xl:pt-12">
            <div className="text-center  sm:max-w-screen-md mx-6 sm:mx-auto py-8">
                    <p className="leading-6 text-indigo-600 font-semibold tracking-wide sm:leading-none text-lg sm:text-xl md:text-xl lg:text-3xl uppercase">
                        {SERVICES_LABEL}
                    </p>
                    <p className="font-bold text-base sm:text-xl leading-7 text-gray-700 lg:mx-auto sm:text-lg max-w-2xl  sm:max-w-3xl sm:mx-auto mt-5 sm:mt-10 md:mt-10 lg:mt-12 xl:mt-12">
                        {SERVICES_TEXT}
                    </p>
                <div className="mx-2 sm:mx-0 mt-10 sm:mt-12 md:mt-10 lg:mt-12 sm:flex sm:justify-center lg:justify-center">
                    <div className="rounded-md shadow">
                        <a href="/training"
                           className="w-full flex items-center justify-center px-8 py-3 border border-transparent leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 text-base sm:text-lg md:px-10">
                            {AVAILABLE_TRAININGS_LABEL}
                        </a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};
