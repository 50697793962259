import React, { FunctionComponent } from 'react';
import { NavigationContainer } from '../navigation/NavigationContainer';
import { HomeOverviewView } from "./HomeOverviewView";
import img from '../assets/HomeOverview.jpg'
import {HOME_HEADER, HOME_SUB_HEADER} from "../CommonService";

export const HomeHeroView: FunctionComponent = () => {
    const {from, data, to, exact, impact} = HOME_HEADER;
    return (
        <>
        <div className="relative bg-white overflow-hidden">
            <div className="max-w-screen-xl xl:max-w-full mx-auto">
                <div className="relative z-10 xl:w-2/5 pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 lg:max-w-2xl lg:w-full xl:max-w-none">
                    <svg
                        className="hidden lg:block absolute right-0 inset-y-0 h-full xl:w-56 w-48 text-white transform translate-x-1/2"
                        fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <polygon points="50,0 100,0 50,100 0,100"/>
                    </svg>
                    <NavigationContainer useWrapper={false} dark={false} customWrapperClass='px-6 sm:px-0 pt-3'/>
                    <main
                        className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-32 lg:px-8 xl:pb-40">
                        <div className="sm:text-center lg:text-left divide-y-2 divide-indigo-700">
                            <h2 className="text-4xl tracking-tight xl:tracking-wide leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                                {from} <span className="text-indigo-600"> {data} </span> {to} {' '}
                                <br className="lg:hidden" />
                                {exact} <span className="text-indigo-600">{impact}</span>
                            </h2>
                            <p className="mt-3 text-base xl:leading-relaxed xl:tracking-wide text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mr-36 lg:ml-0 py-6 lg:text-2xl">
                                {HOME_SUB_HEADER}
                            </p>
                        </div>
                    </main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-3/5">
                <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                     src={img}
                     alt="" />
            </div>
        </div>
        <HomeOverviewView />
        </>
    )
};
