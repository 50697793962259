import React, {FunctionComponent} from 'react';
import { NavigationContainer } from "../navigation/NavigationContainer";
import {COOKIES_PAGE_TITLE, COOKIES_TEXT} from "../CommonService";

export const CookiesPolicyView: FunctionComponent = () => {
    return (
        <div className="pb-40">
                <NavigationContainer useWrapper dark />
            <div className="bg-gray-200">
                <div className="max-w-screen-xl mx-6 md:mx-auto text-center">
                    <h1 className=" py-12 md:py-32 text-2xl md:text-4xl  tracking-tight xl:tracking-wide leading-10 font-extrabold text-black sm:leading-none">{COOKIES_PAGE_TITLE}</h1>
                </div>
                <div className="bg-white xl:pb-40">
                    <div className="max-w-screen-xl mx-auto pt-20 pb-12 text-xl text-gray-700 text-center">
                        {COOKIES_TEXT}
                    </div>
                </div>
            </div>
        </div>
    );
};
