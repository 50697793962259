import React, {FunctionComponent} from 'react';
import {FooterLinkView} from "./FooterLinkView";
import {
    COMPANY_EMAIL,
    COMPANY_NAME,
    COMPANY_PHONE_NUMBER, COOKIES_LABEL,
    IMPRESSUM_LABEL,
    MAIL_LABEL,
    PHONE_LABEL_ABBREVIATED, PRIVACY_HEADER
} from "../CommonService";

const FOOTER_NAVIGATION_LINKS = [
    {
        name: IMPRESSUM_LABEL,
        path: '/impressum',
    },
    {
        name: PRIVACY_HEADER,
        path: '/datenschutz',
    },
    {
        name: COOKIES_LABEL,
        path: '/cookies',
    }
];

export const FooterContainer: FunctionComponent = () => {
    return (
        <div className="h-full">
            <div className="bg-gray-900 text-gray-400 pt-12 pb-8">
                <div className="flex justify-center">
                    { FOOTER_NAVIGATION_LINKS.map(link => <FooterLinkView text={link.name} urlString={link.path}/>)}
                </div>
                <div className="flex my-6 px-64">
                    <div className="w-full border-t border-gray-800"/>
                </div>
                <div className="space-y-2">
                    <div className="flex justify-center text-gray-600">
                        <div className="">
                            ©{' '}{COMPANY_NAME}
                        </div>
                    </div>
                    <div className="flex justify-center text-gray-600">
                        <svg aria-hidden="true" focusable="false" data-icon="phone"
                             className="h-3 self-center pr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path fill="currentColor"
                                  d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"/>
                        </svg>
                        {PHONE_LABEL_ABBREVIATED}: {COMPANY_PHONE_NUMBER}
                    </div>
                    <div className="flex justify-center text-gray-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            focusable="false"
                            className="h-3 self-center pr-3"
                            role="img"
                            viewBox="0 0 512 512">
                            <path
                                fill="currentColor"
                                d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"/>
                        </svg>
                        {MAIL_LABEL}: {COMPANY_EMAIL}
                    </div>
                </div>
            </div>
        </div>
    )
};
