import React, {FunctionComponent, useCallback, useState} from 'react';
import {NavigationLargeView} from "./NavigationLargeView";
import {NavigationSmallView} from "./NavigationSmallView";

interface Props {
    useWrapper: boolean
    customWrapperClass?: string;
    dark: boolean;
}

export const NavigationContainer: FunctionComponent<Props> = ({ useWrapper, dark, customWrapperClass }) => {
    const [expandSmallNavigation, setExpandSmallNavigation] = useState(false);

    const onNavigationToggle = useCallback(() => {
        setExpandSmallNavigation(!expandSmallNavigation)
    }, [expandSmallNavigation]);

    const wrapperBackgroundColour = dark ? 'bg-gray-900' : '';
    const wrapperClass = useWrapper ? 'px-6 pb-4 pt-3' : customWrapperClass;

    return (
        <div className={`${wrapperClass} ${wrapperBackgroundColour}`}>
            <NavigationLargeView dark={dark} onNavigationToggle={onNavigationToggle} />
            {expandSmallNavigation && (
                <NavigationSmallView onClose={onNavigationToggle} />
            )}
        </div>
    )
};
