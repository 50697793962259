import {ChangeEvent, SyntheticEvent, useCallback, useState} from "react";
import {ContactFormData} from "./useSendEmail";

export const useContactForm = (sendEmail: (formData: ContactFormData) => void) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber]= useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const updateFirstName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFirstName(value);
  }, []);

  const updateLastName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setLastName(value);
  }, []);

  const updateTitle = useCallback((event: SyntheticEvent<HTMLSelectElement>) => {
      const { value } = event.currentTarget;
      setTitle(value);
  }, []);

  const updateEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setEmail(value);
  }, []);

  const updatePhoneNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setPhoneNumber(value);
  }, []);

    const updateSubject = useCallback((event: SyntheticEvent<HTMLSelectElement>) => {
        const { value } = event.currentTarget;
        setSubject(value);
    }, []);


    const updateMessage = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setMessage(value);
  }, []);

    const handleFormSubmit = useCallback((event: any) => {
        event.preventDefault();
        sendEmail({ email, firstName, lastName, message, subject, phoneNumber, title});
    }, [sendEmail, email, firstName, lastName, message, subject, phoneNumber, title]);


  return {
      firstName,
      lastName,
      title,
      email,
      phoneNumber,
      subject,
      message,
      updateFirstName,
      updateLastName,
      updateTitle,
      updateEmail,
      updatePhoneNumber,
      updateSubject,
      updateMessage,
      handleFormSubmit,
  }
};
