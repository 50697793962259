import {useCallback, useState} from "react";
import axios from "axios";

export interface ContactFormData {
    firstName: string,
    lastName: string,
    title: string,
    phoneNumber: string,
    email: string,
    message: string,
    subject: string,
}

export const useSendEmail = () => {
    const [result, setResult] = useState();
    const [showResponse, setShowResponse] = useState(false);

    const sendEmail = useCallback((formData: ContactFormData) => {
        const { firstName, lastName, title, phoneNumber, email, message, subject } = formData;
        axios.post('/send', {
            firstName,
            lastName,
            title,
            phoneNumber,
            email,
            message,
            subject,
        })
            .then((response) => {
                setResult(response.data);
                setShowResponse(true);
            })
            .catch(() => {
                setResult({success: false, message: 'Sorry, we were unable to send your message. Please send us an e-mail at contact@actondata.at'});
                setShowResponse(true);
            });
    }, []);

    return { result, showResponse, sendEmail };

};
