import React, {FunctionComponent} from 'react';
import {LAW_ARTICLE_TEXT, ODR_INFO, ODR_USE_INFO, ORR_LINK} from "../CommonService";

export const FurtherInfoView: FunctionComponent = () => {
    return (
        <div className="pt-6 pb-4 text-xl text-gray-700">
            <div>
                {LAW_ARTICLE_TEXT}
            </div>
            <div>
                {ODR_INFO}{' '}
                <a className="no-underline hover:underline text-blue-500" href={ORR_LINK}>{ORR_LINK}</a>
                {'. '}
                {ODR_USE_INFO}
            </div>
        </div>
    );
};
