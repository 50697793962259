import React, {FunctionComponent} from 'react';
import {TeamMemberCardView} from "./TeamMemberCardView";
import {NavigationContainer} from "../navigation/NavigationContainer";
import {ClientsContainer} from "./ClientsContainer";
import {AVAILABLE_TRAININGS_LABEL, GET_IN_TOUCH_LABEL} from "../CommonService";


export const AboutContainer: FunctionComponent = () => {
    return (
        <div className="bg-gray-300">
        <NavigationContainer useWrapper dark />
        <div className="mx-0 sm:mx-0 md:mx-0 lg:mx-24 xl:mx-32 pb-40">
            <div className="lg:grid lg:grid-cols-5 pt-24 lg:gap-12 mx-0 sm:mx-8 md:mx-12 lg:mx-0 xl:mx-0">
                <div className="lg:col-span-5 xl:col-span-4">
                    <TeamMemberCardView />
                </div>
                <div className="xl:-ml-20 max-w-lg sm:max-w-lg md:max-w-none mx-auto sm:mx-auto md:mx-0 lg:col-span-5 xl:col-span-1 md:space-x-10 lg:space-x-10 xl:space-x-0 lg:place-self-center lg:justify-center mt-6 sm:mt-8 md:mt-0 lg:mt-0 xl:mt-0">
                    <div className="md:inline-grid lg:inline-grid rounded-md py-2">
                        <a href="/kontakt"
                           className="md:w-64 lg:w-64 xl:w-64 flex items-center justify-center px-8 py-3 border border-transparent shadow text-base leading-6 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                            {GET_IN_TOUCH_LABEL}
                        </a>
                    </div>
                    <div className="md:inline-grid lg:inline-grid rounded-md py-2">
                        <a href="/training"
                           className="md:w-64 lg:w-64 xl:w-64 flex items-center justify-center px-8 py-3 border border-transparent shadow text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                            {AVAILABLE_TRAININGS_LABEL}
                        </a>
                    </div>
                </div>
            </div>
            <div className="sm:block">
                         <div className="py-12 sm:py-12 md:py-20 px-16">
                            <div className="border-t border-gray-400"/>
                         </div>
                     </div>
            <div className="mx-0 md:mx-8 lg:mx-0 xl:mx-0">
                <ClientsContainer />
            </div>
        </div>
        </div>
    )
};
