import React, {FunctionComponent} from 'react';

interface Props {
    title?: string;
    text: string[];
    addPadding: boolean
}

export const PolicySectionView: FunctionComponent<Props> = ({ title, text, addPadding}) => {
    const paddingClass = addPadding ? 'pb-3' : '';
  return (
      <div className="">
          {!!title && (
              <h2 className="text-2xl pt-6 pb-4 tracking-tight xl:tracking-wide leading-10 font-extrabold text-black sm:leading-none">{title}</h2>
          )}
          <div className="text-xl text-gray-700">
              {text.map((line: string) => <p className={paddingClass}>{line}</p>)}
          </div>
      </div>
  )
};
