import React, {FunctionComponent} from 'react';
import {NavLink} from "react-router-dom";
import {NAVIGATION_LINKS} from "./NavigationService";
import {LogoMarkView} from "./LogoMarkView";

interface Props {
    onClose: () => void;
}

// h-8 w-auto

export const NavigationSmallView: FunctionComponent<Props> = ({ onClose }) => {
    return (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-md">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu"
                     aria-orientation="vertical" aria-labelledby="main-menu">
                    <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                           <LogoMarkView />
                        </div>
                        <div className="-mr-2">
                            <button type="button"
                                    onClick={onClose}
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                                    aria-label="Close menu">
                                <svg className="h-6 w-6" stroke="currentColor" fill="none"
                                     viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="px-2 pt-2 pb-3">
                        {NAVIGATION_LINKS.map(link =>
                            <NavLink className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" to={link.path} exact={link.exact}>{link.name}
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};
