import React, {FunctionComponent} from 'react';
import {NavigationContainer} from "../navigation/NavigationContainer";
import {
    COMPANY_NAME,
    COMPANY_ADDRESS,
    COMPANY_OWNER_LABEL,
    COMPANY_OWNER,
    FINANCE_NUMBER_LABEL,
    FINANCE_NUMBER,
    UID_LABEL,
    UID,
    GERICHTS,
    PHONE_LABEL_ABBREVIATED,
    COMPANY_PHONE_NUMBER,
    MAIL_LABEL,
    COMPANY_EMAIL,
    IMPRESSUM_INFO, IMPRESSUM_LABEL
} from "../CommonService";


export const ImpressumView: FunctionComponent = () => {
    return (
        <>
        <NavigationContainer useWrapper dark />
            <div className="">
                <div className="lg:flex lg:h-screen pb-24 sm:pb-32 md:pb-40">
                    <div className="lg:h-screen lg:w-2/5 text-center sm:text-center lg:text-left sm:pl-0 lg:pl-24 xl:pl-56 pt-20 pb-12 sm:pb-16 md:pb-20 lg:pb-0 bg-gray-100">
                        <h1 className="text-center sm:text-center lg:text-left text-4xl pb-12 tracking-tight xl:tracking-wide leading-10 font-extrabold text-black sm:leading-none">{IMPRESSUM_LABEL}</h1>
                        <div className="text-xl text-gray-700">
                            <div>
                                {COMPANY_OWNER_LABEL}{' '}
                                {COMPANY_OWNER}
                            </div>
                            <div>
                                {COMPANY_NAME}
                            </div>
                            <div>
                                {COMPANY_ADDRESS.line_1}
                            </div>
                            <div>
                                {COMPANY_ADDRESS.line_2}
                            </div>
                            <div>
                                {FINANCE_NUMBER_LABEL}{' '}
                                {FINANCE_NUMBER}
                            </div>
                            <div>
                                {UID_LABEL}{' '}
                                {UID}
                            </div>
                            <div>
                                {GERICHTS}
                            </div>
                            <div>
                                {PHONE_LABEL_ABBREVIATED}{': '}
                                {COMPANY_PHONE_NUMBER}
                            </div>
                            <div>
                                {MAIL_LABEL}{': '}
                                {COMPANY_EMAIL}
                            </div>
                        </div>
                     </div>

                    <div className="lg:w-3/5 mx-4 sm:mx-10 md:mx-20 lg:mx-0  md:text-center lg:text-left pt-12 sm:pt-16 md:pt-20 lg:pt-40 bg-white lg:pl-24 lg:pr-56">
                        <div className="text-lg text-gray-900 tracking-wide leading-relaxed ml-auto">
                            {IMPRESSUM_INFO}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};
