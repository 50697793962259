import React, {FunctionComponent} from 'react';
import picture from '../assets/Bild_ThomasPatloch_NEW.png';
import {ABOUT_ME_DESCRIPTION, CEO_LABEL, COMPANY_OWNER} from "../CommonService";

export const TeamMemberCardView: FunctionComponent = () => {
    return (
        <div className="sm:flex sm:items-center sm:flex-col md:grid  lg:grid-rows-4 grid-flow-col sm:gap-0 md:gap-x-3 lg:gap-5 xl:gap-5 gap-y-0 md:pb-16 lg:pb-0">
            <div className="place-self-start sm:flex-1 mx-8 sm:mx-auto md:mx-0 pb-6 sm:pb-6 md:pb-0 sm:order-1  md:row-start-1 lg:row-start-auto md:row-span-3 lg:row-span-4">
                <div className="">
                <img alt="Trainer" className="mx-auto sm:mx-0 md:place-self-start max-w-xs sm:max-w-xs md:max-w-xs lg:max-w-sm rounded-2xl xl:h-auto object-cover" src={picture}/>
                </div>
            </div>
            <div className="sm:flex-1 sm:order-2 text-center sm:text-center md:text-left pb-3 sm:pb-3 md:pb-0 md:row-start-1 md:col-start-2 lg:row-start-auto lg:col-start-auto md:row-span-1 md:col-span-1 md:self-start lg:self-center xl:self-start text-xl font-medium text-gray-800 tracking-wide">
                {COMPANY_OWNER}
                <br/>
                <span className="text-indigo-600 font-medium lg:tracking-wide">{CEO_LABEL}</span>
            </div>
            <div className="xl:mr-12 place-self-auto sm:place-self-auto md:place-self-start lg:place-self-start sm:flex-1 text-center sm:text-center md:text-left px-12 sm:px-12 md:px-0 sm:pb-1 md:pb-0 sm:order-3 sm:ml-4 md:ml-0 row-span-2 sm:row-span-1 md:row-span-2 col-span-1 text-lg text-gray-600 leading-8">
                {ABOUT_ME_DESCRIPTION.line_1}
                <br/>
                {ABOUT_ME_DESCRIPTION.line_2}
                <br />
            </div>
            <div className="sm:flex-1 sm:order-4 lg:row-span-1 md:row-start-1 lg:row-start-auto md:col-start-3 lg:col-start-auto place-self-auto sm:place-self-auto lg:place-self-start pr-8">
                <div className="col-span-1">
                    <a href="https://www.linkedin.com/in/thomas-patloch" aria-label="linked in profile">
                    <svg aria-hidden="true" focusable="false" data-icon="linkedin"
                         className="mx-auto sm:mx-0 h-8 text-indigo-600 mt-6 sm:mt-6 md:mt-0 lg:mt-0" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 448 512">
                        <path fill="currentColor"
                              d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                        />
                    </svg>
                    </a>
                </div>
            </div>
        </div>
    )
};
