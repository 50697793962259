import React, {FunctionComponent} from 'react';
import {Link } from "react-router-dom";

interface Props {
    text: string;
    urlString: string;
}

export const FooterLinkView: FunctionComponent<Props> = ({ text, urlString }) => {
    return (
        <div className="sm:px-4 py-2 xl:py-10 m-2">
            <Link to={urlString}>
                {text}
            </Link>
        </div>
    );
};
