import React, {ChangeEvent, FunctionComponent} from 'react';

interface Props {
    label: string;
    id: string;
    columns: number;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InputView: FunctionComponent<Props> = ( { label, id, columns, value, onChange }) => {
    return (
        <div className={`col-span-6 sm:col-span-${columns}`}>
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700">
                {label}
            </label>
            <input
                onChange={onChange}
                value={value}
                required={true}
                id={id}
                className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
        </div>
    );
};
