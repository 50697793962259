import React, { FunctionComponent, SyntheticEvent} from 'react';

interface Props {
    label: string;
    options: string[];
    id: string;
    cols: number;
    value: string;
    onSelect: (event: SyntheticEvent<HTMLSelectElement>) => void;
}

export const OptionView: FunctionComponent<Props> = ({ label, options, id, cols, value, onSelect }) => {
    return (
        <div className={`col-span-6 sm:col-span-${cols}`}>
            <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700">
                {label}
            </label>
            <select
                onChange={onSelect}
                value={value}
                id={id}
                className="mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
                <option label=" "/>
                {options.map(option => <option  placeholder="">{option}</option> )}
            </select>
        </div>
    );
};
