import React, {ChangeEvent, FunctionComponent} from 'react';

interface Props {
    label: string;
    id: string;
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    value: string;
}

export const TextAreaView: FunctionComponent<Props> = ({ label, id, onChange, value }) => {
    return (
        <div className="col-span-6">
            <label
                htmlFor={id}
                className="block text-sm leading-5 font-medium text-gray-700"
            >
                {label}
            </label>
            <div className="rounded-md shadow-sm">
                <textarea
                    value={value}
                    required
                    onChange={onChange}
                    id={id}
                    rows={3}
                    className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
            </div>
            <p className="mt-2 text-sm text-gray-500">

            </p>
        </div>
    );
};
