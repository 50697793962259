import React, {FunctionComponent} from 'react';

interface Props {
    description: string[];
    name: string;
    imageLayout: boolean;
}

export const DescriptionView: FunctionComponent<Props> = ({ name, description, imageLayout }) => {
    const nameClass = imageLayout ? 'flex-1 text-3xl xl:text-4xl font-extrabold text-center sm:text-center md:text-center lg:text-center xl:text-left'
        : 'flex-1 text-3xl xl:text-4xl font-extrabold text-center sm:text-center md:text-center lg:text-left xl:text-left';
    const descriptionClass = imageLayout ? 'mx-2 sm:mx-4 mt-6 text-lg text-gray-600 text-center sm:text-center md:text-center lg:text-center xl:text-left'
        : 'mt-6 text-lg text-gray-600 text-center sm:text-center md:text-center lg:text-left xl:text-left';

    return (
        <>
        <div className={nameClass}>
            {name}
        </div>
        <div className={descriptionClass}>
        {description.map((paragraph: string) => (
                    <div className="mb-4">
                        {paragraph}
                        <br/>
                    </div>
                )
        )}
        </div>
        </>
    )
};
