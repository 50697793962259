import React, {FunctionComponent} from 'react';
import {NavigationContainer} from "../navigation/NavigationContainer";
import img from '../assets/TrainingOverview.jpg'
import {TRAINING_OVERVIEW_HEADER, TRAINING_OVERVIEW_SUB_HEADER} from "../CommonService";

export const TrainingOverviewHeader: FunctionComponent = () => {
    const { easy, great, best } = TRAINING_OVERVIEW_HEADER;
  return (
      <>
     <div className="overflow-hidden">
         <div className="xl:flex lg:flex md:flex-auto">
                 <div className="xl:w-1/2 lg:w-1/2 md:w-full pb-4">
                         <NavigationContainer useWrapper dark={false} />
                     <div className="text-center sm:text-center lg:text-left pl-3 sm:pl-12 xl:pl-24 pt-16  pr-3 xl:pr-24 sm:pr-12 pb-20">
                         <h2 className="text-4xl tracking-tight xl:tracking-wide leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                             {easy} <span className="text-indigo-700"> {great} </span>
                         </h2>
                         <p className="text-gray-800 font-extrabold xl:leading-relaxed xl:tracking-wide sm:text-lg  md:text-xl lg:text-2xl xl:text-3xl mt-6">
                             {best}
                         </p>
                         <p className="font-medium text-gray-700 mt-12 text-base lg:text-lg xl:text-2xl sm:text-lg sm:mr-8">
                             {TRAINING_OVERVIEW_SUB_HEADER}
                         </p>
                     </div>
                 </div>
             <div className="xl:w-1/2 lg:w-1/2 md:w-full h-64">
                 <img className="sm:w-full sm:h-full md:w-full md:h-full lg:h-auto object-cover" alt="alt" src={img} />
             </div>
         </div>
     </div>
    </>
  );
};
