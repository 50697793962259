import React, {FunctionComponent} from 'react';
import {Link, useRouteMatch, } from "react-router-dom";

interface Props {
    courseName: string;
    iconSvgPath: string;
}


export const TrainingOfferItemView: FunctionComponent<Props> = ({ courseName, iconSvgPath}) => {
    let { url } = useRouteMatch();
    return (
        <div className="lg:flex lg:w-1/2 md:w-3/4 md:mx-auto px-2 py-4">
            <div className="bg-gray-800 lg:flex-1 rounded-lg shadow-lg hover:bg-gray-700">
                <Link to={`${url}/${courseName}`}
                      className="inline-flex w-full text-lg py-12 px-8 leading-6 font-medium text-gray-300 transition ease-in-out duration-150">
                    <div className="pr-12">
                    <svg className="h-6 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={iconSvgPath}/>
                    </svg>
                    </div>
                    <div className="">
                    {courseName}
                    </div>
                    <div className="ml-auto">
                        <svg className="h-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"/>
                        </svg>
                    </div>
                </Link>
            </div>
        </div>
    )
};
