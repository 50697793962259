import React, {FunctionComponent, useCallback, useState} from 'react';
import {AgendaTopic} from "../CommonService";
import {AgendaSubTopicView} from "./AgendaSubTopicView";

interface Props {
    agendaTopic: AgendaTopic;
}

export const AgendaTopicView: FunctionComponent<Props> = ({agendaTopic}) => {
    const {mainTopic, subTopics} = agendaTopic;
    const [isSelected, setIsSelected] = useState(false);

    const onToggleTopic = useCallback(() => {
        setIsSelected(!isSelected)
    }, [isSelected]);

    return (
        <article className="border-b">
            <div className={isSelected ? 'border-l-2 bg-gray-100 border-indigo-700' : 'border-l-2 border-transparent bg-white' }>
                <header className="flex justify-between items-center p-5 md:pl-2 lg:pl-8 xl:pl-8 md:pr-2 lg:pr-8 xl:pr-8 select-none">
                    <span className={isSelected ? 'text-indigo-700 font-thin md:text-lg lg:text-xl xl:text-xl' : 'text-gray-900 font-thin md:text-lg lg:text-xl xl:text-xl'}>
                    {mainTopic}
                    </span>
                    {!!subTopics?.length && (
                    <div className={isSelected ? 'cursor-pointer rounded-full border border border-indigo-700 w-7 h-7 flex items-center justify-center bg-indigo-700' : 'cursor-pointer rounded-full border border-gray-600 w-7 h-7 flex items-center justify-center'}>
                        <svg
                            onClick={onToggleTopic}
                            aria-hidden="true"
                            className=""
                            data-reactid={isSelected ? '281' : '266'}
                            fill="none"
                            height="24"
                            stroke={isSelected ? 'white' : '#606F7B'}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg">
                            <polyline points={isSelected ? '18 15 12 9 6 15' : '6 9 12 15 18 9'}>
                            </polyline>
                        </svg>
                    </div>
                    )}
                </header>
                {isSelected && !!subTopics?.length && (
                    <AgendaSubTopicView subTopics={subTopics} />
                )}
            </div>
        </article>
    )
};
