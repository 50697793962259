import React, {FunctionComponent, useRef} from 'react';
import {TrainingOfferItemView} from "./TrainingOfferItemView";
import {TRAINING_OFFERS} from "./TrainingService";
import {TRAINING_OFFER_LABEL} from "../CommonService";

export const TrainingOverviewMain: FunctionComponent = () => {
    const ref = useRef(null);

    // @ts-ignore
    const scrollToOffers = () => ref.current.scrollIntoView({behavior: 'smooth'});

    return (
        <div className="px-2 sm:px-16 md:px-20 lg:px-24 xl:px-56 bg-gray-200 pb-16 lg:pb-40 xl:pb-40 md:pb-32 sm:pb-16 ">
            <p className="flex-1 text-gray-800 font-semibold  sm:text-base sm:leading-none md:text-xl lg:text-2xl uppercase text-center pb:0 xl:pb-12 lg:pb-12 md:pb-10 sm:pb-0 pt-12 xl:pt-32 lg:pt-32 md:pt-20 sm:pt-12">
                {TRAINING_OFFER_LABEL}
                      </p>
            <svg
                onClick={scrollToOffers}
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                 data-icon="angle-down"
                className="lg:animate-bounce md:animate-none h-24 mx-auto text-indigo-600 hover:text-indigo-400 hidden md:block lg:block xl:block"
                role="img"
                 viewBox="0 0 320 512">
                <path fill="currentColor"
                      d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/>
            </svg>
            <div
                ref={ref}
                className="lg:flex content-center flex-wrap sm:py-12 sm:px-12 sm:pt-8"
            >
                        {TRAINING_OFFERS.map(trainingOffer => (
                    <TrainingOfferItemView courseName={trainingOffer.name} iconSvgPath={trainingOffer.iconSvgPath} key={trainingOffer.name}/>
                ))}
            </div>
        </div>
    )
};

