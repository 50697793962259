import React, {FunctionComponent} from 'react';
import {AGENDA_LABEL, AgendaTopic} from "../CommonService";
import {AgendaTopicView} from "./AgendaTopicView";

interface Props {
    agenda: AgendaTopic[];
}

export const AgendaContainer: FunctionComponent<Props> = ({ agenda }) => {
    return (
        <div>
            <div className="flex-1 text-2xl font-medium tracking-wider pl-3 pb-6 sm:pb-6 md:pb-10 lg:pb-4 xl:pb-4 pt-16 sm:pt-16 md:pt-16 lg:pt-0 xl:pt-0 text-center sm:text-center md:text-center lg:text-left xl:text-left">
                {AGENDA_LABEL}
            </div>
            <section className="shadow max-w-screen-md mx-auto">
            {agenda.map((agendaTopic: AgendaTopic) => <AgendaTopicView agendaTopic={agendaTopic}/>)}
            </section>
        </div>
    )
};
