import React, {FunctionComponent} from 'react';

interface Props {
    subTopics: string[];
}

export const AgendaSubTopicView: FunctionComponent<Props> = ({ subTopics }) => {
    return (

                        <div>
                            <div className="pl-8 pr-8 pb-5 text-gray-700">
                                <ul className="pl-4">
                                    {subTopics.map((subTopic: string) => <li className="pb-2">
                                        {subTopic}
                                    </li> )}
                                </ul>
                            </div>
                        </div>
    )
};
