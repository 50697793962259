import {CONTACT_LABEL, HOME_LABEL, REFERENCE_LABEL, TRAINING_LABEL} from "../CommonService";

export const NAVIGATION_LINKS = [
    {
        name: HOME_LABEL,
        path: '/',
        exact: true,
    },
    {
        name: TRAINING_LABEL,
        path: '/training',
        exact: false,
    },
    {
        name: REFERENCE_LABEL,
        path: '/referenzen',
        exact: false,
    },
    {
        name: CONTACT_LABEL,
        path: '/kontakt',
        exact: false,
    },
];
